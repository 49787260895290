var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-questionnaire"},[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('forms')}},[_c('a-button',{attrs:{"slot":"custom-button","type":"primary","icon":"plus","disabled":!_vm.canChangeConfig},on:{"click":function($event){_vm.goToCreateNewFormPage(parseInt(_vm.$route.params[_vm.EntityTypes.CHAT_ID]))}},slot:"custom-button"},[_vm._v(" "+_vm._s(_vm.$t("form_create_new_form"))+" ")])],1),(!_vm.$store.getters.canChangeConfig)?_c('permissions-banner',{staticClass:"mb-5",attrs:{"permission":_vm.SitePermissionEnum.ChangeConfig}}):_vm._e(),(!_vm.getTagsByFieldKey('forms'))?[(_vm.$store.getters.getHaveBrand)?_c('a-alert',{attrs:{"type":"warning"}},[_c('extended-help-message',{attrs:{"slot":"message","message":_vm.$t('alert_message_questionnaire_brand_domain', [ _vm.$store.state.chatState.chat.brand.botname ]),"items":{
          'alert_message_questionnaire_brand_domain_is_set': _vm.$t('alert_message_questionnaire_brand_domain_is_set')
        }},slot:"message"})],1):_vm._e(),_c('list',{staticClass:"mt-5",attrs:{"config":{
        'data': _vm.forms,
        'paginatedBy': 5,
        'searchFn': function (item, index, text) { return item.value.name.toLowerCase().includes(text.trim().toLowerCase()); },
        'keyFn': function (item) { return item.value.guid; },
        'direction': _vm.ListDirection.Horizontal,
        'grid': {
          xs: 24,
          md: 12,
          xl: 8,
          xxl: 6
        }
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('list-item-form-card',{attrs:{"form":item.value,"delete-form-fn":function () { return _vm.deleteFormFn(item.value.guid); },"watch-form-fn":function () { return _vm.goToWatchFormPage(_vm.$store.getters.chatId, item.value.guid); },"setup-form-fn":function () { return _vm.goToSetupFormPage(_vm.$store.getters.chatId, item.value.guid); },"copy-form-fn":function () { return _vm.gotoCopyFormPage(_vm.$store.getters.chatId, item.value.guid); },"disabled":!_vm.canChangeConfig}})]}}],null,false,3456477425)})]:_c('div',{staticClass:"w-full mb-5 relative"},[_c('not-available-options-overlay',{staticClass:"shadow relative",attrs:{"description":_vm.$t('option_description_forms'),"video":_vm.$i18n.locale !== 'ru' ?
        'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_form_en.m4v' :
        'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_form_ru.m4v'}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }